<script lang="ts">
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  useState,
} from '#imports'
import type { Ref } from 'vue'

export default {
  props: {
    height: {
      type: Number,
      default: 4,
    },
    color: {
      type: String,
      default:
        'repeating-linear-gradient(to right,#00dc82 0%,#34cdfe 50%,#0047e1 100%)',
    },
  },
  setup() {
    const ready = ref(false)
    const indicator = useLoadingState()
    onBeforeUnmount(() => indicator.value?.clear)

    onMounted(() => {
      ready.value = true
    })
    return {
      ready,
      indicator,
    }
  },
}

export function useLoadingIndicator(
  opts = {
    duration: 2e3,
    throttle: 0,
  },
) {
  const progress = ref(0)
  const isLoading = ref(false)
  const step = computed(() => 1e4 / opts.duration)
  let _timer: any = null
  let _throttle: any = null
  const clear = () => {
    clearInterval(_timer)
    clearTimeout(_throttle)
    _timer = null
    _throttle = null
  }
  const _increase = (num) => {
    progress.value = Math.min(100, progress.value + num)
  }
  const _startTimer = () => {
    if (process.client) {
      _timer = setInterval(() => {
        _increase(step.value)
      }, 100)
    }
  }
  const _hide = async () => {
    await nextTick()
    clear()
    if (process.client) {
      setTimeout(() => {
        isLoading.value = false
        setTimeout(() => {
          progress.value = 0
        }, 20)
      }, 20)
    } else {
      progress.value = 0
      isLoading.value = false
    }
  }
  const start = () => {
    clear()
    progress.value = 0
    isLoading.value = true
    if (opts.throttle) {
      if (process.client) _throttle = setTimeout(_startTimer, opts.throttle)
    } else {
      _startTimer()
    }
  }

  const finish = () => {
    progress.value = 100
    _hide()
  }

  return {
    progress,
    isLoading,
    start: process.client ? start : null,
    finish: process.client ? finish : null,
    clear: process.client ? clear : null,
  }
}
function createProxyState(key) {
  // stupid workaround not to lose methods
  return useState<any>(key, () => null)
}
export function useLoadingState() {
  const state = createProxyState('loadingState')
  if (!state.value?.start) state.value = useLoadingIndicator()

  return state as Ref
}
</script>

<template>
  <div
    v-if="!ready || indicator.isLoading"
    class="pointer-events-none fixed inset-2 z-[999999]"
    :style="{
      //width: `${indicator.progress}%`,
      //height: `${height}px`,
      opacity: indicator.isLoading || !ready ? 1 : 0,
      //background: color,
      //backgroundSize: `${(100 / indicator.progress) * 100}% auto`,
      transition: 'width 0.1s, height 0.2s, opacity 0.25s 0.1s',
    }"
  >
    <div
      class="fixed flex w-[calc(100vw-1rem)] items-center justify-center rounded-[1.25rem] bg-dark/30 backdrop-blur-2xl lg:w-[calc(100vw-2rem)]"
      :class="{
        'h-[calc(100vh-1rem)] lg:h-[calc(100vh-2rem)]':
          indicator.isLoading || !ready,
        'h-0': !indicator.isLoading && ready,
      }"
      :style="{
        pointerEvents: indicator.isLoading || !ready ? 'auto' : 'none',
      }"
    >
      <div class="loader" />
    </div>
    <slot />
  </div>
</template>

<style scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #424096;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
