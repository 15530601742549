<script lang="ts" setup>
import NotFoundImage from '~/assets/svgs/404.svg?component'
import IconLogo from '~/assets/svgs/crossarea_logo.svg?component'
import ErrorImage from '~/assets/svgs/error.svg?component'

const { error } = defineProps({
  error: Object,
})

function handleError() {
  // Log the error details to a server-side log
  console.error(error)
  clearError({ redirect: '/' })
}
</script>

<template>
  <div
    v-if="error.statusCode === 404"
    class="grid h-screen grid-cols-1 items-center bg-gray-25/20 md:grid-cols-2"
  >
    <div
      class="order-2 mx-4 w-full md:order-1 md:ml-auto md:mr-10 md:w-1/2"
    >
      <NuxtLink class="mb-5 flex w-full items-center" to="/">
        <IconLogo class="w-[250px] text-[#3f3d56]" />
      </NuxtLink>
      <h1 class="text-[10vw] font-[500] text-[#6c63ff]">
        {{ error.statusCode }}
      </h1>
      <h2 class="mb-10 text-h3 text-[#3f3d56]">
        {{ error.message }}
      </h2>
      <div class="mt-20 flex gap-5">
        <GenericButton to="/">
          Home
        </GenericButton>
      </div>
    </div>
    <div class="order-1 flex h-full items-center bg-white md:order-2">
      <NotFoundImage class="w-full  text-black" />
    </div>
  </div>

  <div
    v-else
    class="grid h-screen grid-cols-1 items-center bg-gray-25/20 md:grid-cols-2"
  >
    <div
      class="order-2 mx-4 w-full md:order-1 md:ml-auto md:mr-10 md:w-1/2"
    >
      <NuxtLink class="mb-5 flex w-full items-center" to="/">
        <IconLogo class="w-[250px] text-[#3f3d56]" />
      </NuxtLink>
      <h1 class="text-[10vw] font-[500] text-[#6c63ff]">
        {{ error.statusCode }}
      </h1>
      <h2 class="mb-10 text-h3 text-[#3f3d56]">
        Oops! Something went wrong.
      </h2>
      <details>
        {{ error }}
      </details>
      <p>
        We're sorry for the inconvenience. Our team has been notified and will
        look into the issue.
      </p>
      <div class="mt-20 flex gap-5">
        <GenericButton to="/">
          Home
        </GenericButton>
      </div>
    </div>
    <div class="order-1 flex h-full items-center bg-white md:order-2">
      <ErrorImage class="w-full  text-black" />
    </div>
  </div>
</template>
