import { defineStore } from 'pinia'

export const useSiteStore = defineStore({
  id: 'site-stores',
  state: () => ({
    site: null,
    isLoading: false,
    isMobile: false,
    isScrollLocked: false,
    featureFlags: null,
    isSearchOpen: false,
  }),
  actions: {
    setFeatureFlags(featureFlags) {
      this.featureFlags = featureFlags
    },
    lockScroll() {
      this.isScrollLocked = true
      // lock the body scroll
      document.body.style.overflow = 'hidden'
    },
    unlockScroll() {
      this.isScrollLocked = false
      // unlock the body scroll
      document.body.style.overflow = 'auto'
    },
  },
  getters: {
    getSite: (state) => {
      return state.site
    },
  },
})
