import { defineStore } from 'pinia'

interface SearchState {
  searchQuery: string
  searchArray: string[]
  searchHistory: string[]
  uiState: any
  currentSearchView: string
}

export const useSearchStore = defineStore({
  id: 'search-stores',
  persist: {
    storage: persistedState.localStorage,
  },
  state: (): SearchState => {
    return {
      searchQuery: '',
      searchArray: [],
      searchHistory: [],
      uiState: {},
      currentSearchView: 'expanded',
    }
  },
  getters: {
    getSearchQuery: (state: SearchState) => {
      return state.searchQuery
    },
    getSearchArray: (state: SearchState) => {
      return state.searchArray
    },
    getSearchHistory: (state: SearchState) => {
      return state.searchHistory
    },
  },
  actions: {
    setCurrentSearchView(this: SearchState, view: string) {
      this.currentSearchView = view
    },
    setUiState(this: SearchState, uiState: any) {
      this.uiState = uiState
    },
    clearUiState(this: SearchState) {
      this.uiState = {}
    },
    setSearchQuery(this: SearchState, query: string) {
      this.searchQuery = query
    },
    clearSearchQuery(this: SearchState) {
      this.searchQuery = ''
    },
    pushSearchArray(this: SearchState, query: string) {
      this.searchArray.push(query)
    },
    removeFromSearchArray(query: string) {
      this.searchArray = this.searchArray.filter(item => item !== query)
    },
    clearSearchArray(this: SearchState) {
      this.searchArray = []
    },
  },
})
